import React, { useEffect, useState  } from 'react';
import "../assets/css/main.css"
import "../assets/css/bootstrap.css"
import httpService from '../services/httpService';
import $ from 'jquery';
import { useAppSelector } from '../hooks';

import {
  CircularProgress
} from '@mui/material';

type Telemarketer = {
   id: string
   english_name: string
}

type Case = {
   id: string
   case_name: string
}


type CaseResponse = {
  telemarketer_name: string
  case_name: string
  working_hours: number
  meeting_hours: number
  calling_hours: number
  calling_time_ratio: string
  daily_goal: string
  successes_count: number
  daily_goal_percent: number
  calls_count: number
  avg_call_time: number
  long_calls_count: number
  find_dm_count: number
  find_dm_ratio: string
  find_dm_avg_call_time: number
  contact_reject_count: number
  contact_reject_ratio: string
}

type AxiosResponse = {
  status: string
}

type TMCombineResponse = {
  headers: Array<string>
  rows: Array<Array<object>>
}

const TMDashboard = () => {
  const [telemarketers, setTelemarketers] = useState([]);
  const [cases, setCases] = useState([]);
  const [currentDate, setCurrentDate] = useState<string>('')
  const [currentTelemarketerId, setCurrentTelemarketerId] = useState<number>()
  const [currentCaseId, setCurrentCaseId] = useState<number>() 
  const [caseResponse, setCaseResponse] = useState<CaseResponse | null>()
  const [loading, setLoading] = useState(false);
  const [loadingTmCombine, setLoadingTmCombine] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [workingHours, setWorkingHours] = useState<string>();
  const [meetingHours, setMeetingHours] = useState<string>();
  const [tmCombineResponse, setTMCombineResponse] = useState<TMCombineResponse | null>()
  const currentUserEmail = useAppSelector((state) => state.user?.email);
  const currentUserRole = useAppSelector((state) => state.user?.role);

  const fetchTelemarketers = async () => {
    let params: any = {};
    if(currentUserRole !== "pm"){
        params['email']  = currentUserEmail;
    }
    httpService.get('/tm_dashboard/telemarketers', {params}).then(function (response) {
        setTelemarketers(response.data);
        if(response.data.length === 1){
            let params: any = {};
            params['telemarketer_id']  = parseInt((response.data[0] as Telemarketer).id.toString());
            httpService.get('/tm_dashboard/cases', {params}).then(function (response){
                setCases(response.data);
            });
        }
    });
  };

  const fetchCases = async () => {
    var telemarketer_id = $("#telemarketer-id").val()!;
    setMarkDisabled();
    if(telemarketer_id !== 0){
        let params: any = {};
        params['telemarketer_id']  = parseInt(telemarketer_id.toString());
       const response = await httpService.get('/tm_dashboard/cases', {params}); 
       setCases(response.data);
    }
    else{
        alert("Please select telemarketer.");
    }
  };

  useEffect(() => {
    fetchTelemarketers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const saveCurrentCase = () => {
    var log_date = $("#log-date").val()!;
    var telemarketer_id = $("#telemarketer-id").val()!;
    var case_id=$("#case-id").val()!;
    setCurrentDate(log_date.toString());
    setCurrentTelemarketerId(parseInt(telemarketer_id.toString()));
    setCurrentCaseId(parseInt(case_id.toString()));
  }

  const setColor = (ratio:string, id:string, thresh:number) => {
    var num_ratio = parseInt(ratio.slice(0, -1));
    if(isNaN(num_ratio)){
        num_ratio = 0;
    }
    if(num_ratio < thresh) {
        $(id).attr("style", "color:#E30B3D");
    } else {
        $(id).attr("style", "color:#3DE30B");
    }
  }

  const displayTmCombine = () => {
    var telemarketer_id = $("#telemarketer-id").val()!;
    var case_id=$("#case-id").val()!;

    telemarketer_id = parseInt(telemarketer_id.toString());
    case_id = parseInt(case_id.toString());

    let params: any = {};
    params['telemarketer_id']  = telemarketer_id;
    params['case_id'] = case_id;
    setLoadingTmCombine(true);

    httpService.get('/tm_dashboard/tm-combine', { params }).then(function (response) {
        var newTMResponse = (response.data as TMCombineResponse);
        setTMCombineResponse(newTMResponse);
        setLoadingTmCombine(false);
        setDisabled(false);
    }).catch(function (error) {
        console.error(error);
        alert("Cannot load the data.");
        setLoadingTmCombine(false);
    });
  }

  const refreshPage = () => {
    var log_date = $("#log-date").val()!;
    var telemarketer_id = $("#telemarketer-id").val()!;
    var case_id=$("#case-id").val()!;

    log_date = log_date.toString();
    telemarketer_id = parseInt(telemarketer_id.toString());
    case_id = parseInt(case_id.toString());

    if (log_date === "" || case_id === null || case_id === 0 || telemarketer_id === null || telemarketer_id === 0) {
        alert("請先選擇日期/訪員/專案");
        setLoading(false);
        return true;
    }
    else{
      let params: any = {};
      params['log_date'] = log_date;
      params['telemarketer_id']  = telemarketer_id;
      params['case_id'] = case_id;

      httpService.get('/tm_dashboard/load-case', { params }).then(function (response) {
        var newCaseResponse = (response.data as CaseResponse);
        if (newCaseResponse.calling_hours != null && newCaseResponse.working_hours != null) {
            var calling_time_ratio = Math.round((newCaseResponse.calling_hours / newCaseResponse.working_hours) * 100);
            newCaseResponse.calling_time_ratio = calling_time_ratio + "%";
        }
        else{
          newCaseResponse.calling_time_ratio = "";
        }
        
        if (newCaseResponse.find_dm_count != null && newCaseResponse.calls_count != null) {
            var find_dm_ratio = Math.round((newCaseResponse.find_dm_count / newCaseResponse.calls_count) * 100);
            newCaseResponse.find_dm_ratio = find_dm_ratio + "%";
        }
        else{
          newCaseResponse.find_dm_ratio = "";
        }
        
        if(newCaseResponse.contact_reject_count != null && newCaseResponse.calls_count != null){
            var contact_reject_ratio = Math.round((newCaseResponse.contact_reject_count / newCaseResponse.find_dm_count) * 100);
            newCaseResponse.contact_reject_ratio = contact_reject_ratio + "%";
        } else {
            newCaseResponse.contact_reject_ratio = "";
        }

        setCaseResponse(newCaseResponse);
        setWorkingHours((newCaseResponse.working_hours || "").toString());
        setMeetingHours((newCaseResponse.meeting_hours || "").toString())
        setColor(newCaseResponse.calling_time_ratio, "#calling-time-ratio", 50);
        setColor(newCaseResponse.daily_goal_percent+"%", "#daily-goal-percent", 100);
        setLoading(false);
        displayTmCombine();
      }).catch(function (error) {
        console.error(error);
        alert("Cannot load the data.");
        setLoading(false);
      });
    }
  }

  const updateCurrentCase = () => {
    let params: any = {};
    var work_hours = parseFloat($("#working-hours").val()!.toString());
    var meet_hours = parseFloat($("#meeting-hours").val()!.toString());
    var log_date = $("#log-date").val()!;
    var telemarketer_id = $("#telemarketer-id").val()!;
    var case_id=$("#case-id").val()!;
    if(log_date === "" || telemarketer_id === 0 || case_id === 0){
        alert("請先選擇日期/訪員/專案");
        setLoading(false);
    }
    else{
        params['current_date'] = log_date.toString();
        params['current_telemarketer_id']  = parseInt(telemarketer_id.toString());
        params['current_case_id'] = parseInt(case_id.toString());
        var total_hours = 0;
        if(!isNaN(work_hours)){
            params['working_hours'] = work_hours;    
            total_hours = total_hours + work_hours;
        }
        else{
            work_hours = 0;
        }

        if(!isNaN(meet_hours)){
            params['meeting_hours'] = meet_hours;    
            total_hours = total_hours + meet_hours;
        }
        else{
            meet_hours = 0;
        }
        console.log(total_hours);
        console.log(work_hours);
        console.log(meet_hours);
        console.log(work_hours < meet_hours);
        
        if(total_hours > 6.5){
            alert("總外撥時數 + 總會議時數 不得大於 6.5 小時");
            setLoading(false);
        }
        else{
            if(caseResponse != null && caseResponse.calling_hours != null && work_hours < caseResponse.calling_hours && meet_hours < caseResponse.calling_hours){
                console.log(caseResponse);
                console.log(caseResponse.calling_hours);
                console.log(work_hours < caseResponse.calling_hours);
                alert("外撥時數不能小於系統內的線上工時");
                setLoading(false);
            }
            else{
                setLoading(true);
                httpService.get('/tm_dashboard/update-hours', {params}).then(function (response) {
                    var newResponse = (response.data as AxiosResponse);
                    console.log(newResponse)
                    if(newResponse.status === "ERROR"){
                        alert("當天所有專案的總工時超過6.5小時");
                        setLoading(false);  
                    }
                    else{
                        console.log("working hours updated");
                        alert("更新完成");
                        refreshPage();
                        setLoading(false);    
                    }
                }).catch(function (error) {
                    console.error(error);
                    alert("Cannot update hours.");
                    setLoading(false);
                });
            }
        }
    }
  }
  
  const workingHoursChange = (val:string) => {
    var parsed_value = parseFloat(val);
    if(!isNaN(parsed_value)){
        setWorkingHours(parsed_value.toString());   
    }
    else{
        setWorkingHours("");
    }
  }

  const meetingHoursChange = (val:string) => {
    var parsed_value = parseFloat(val);
    if(!isNaN(parsed_value)){
        setMeetingHours(parsed_value.toString());    
    }
    else{
        setMeetingHours("");
    }
  }

  const updateCaseBtnClick = () => {
    setLoading(true);
    saveCurrentCase();
    updateCurrentCase();
  }

  const loadCaseBtnClick = () => {
    setLoading(true);
    saveCurrentCase();
    refreshPage();
  }

  const setMarkDisabled = () => {
    setDisabled(true);
    setCaseResponse(null);
    setWorkingHours("");
    setMeetingHours("");
    setTMCombineResponse(null);
  }


  return (
    <>
      <div id="container">
            <div className="col-md-12" id="header">
                TM每日績效記錄 
            </div>

            <div className="col-md-12 form-group row" id="filter">
                <div className="col-3 filter">
                    <input className="form-control" type="date" id="log-date" onChange={setMarkDisabled}/>
                </div>
                <div className="col-3 filter">
                    <select className="form-control" id="telemarketer-id" onChange={fetchCases}>
                      <option value="0">--choose one --</option>
                      {(telemarketers as Telemarketer[]).map((item) => 
                        (
                          <option value={item.id} selected={telemarketers.length === 1}>{item.english_name}</option>
                        )
                      )}           
                    </select>
                </div>
                <div className="col-3 filter">
                <select className="form-control" id="case-id" onChange={setMarkDisabled}>    
                  <option value="0">--choose one --</option>
                    {(cases as Case[]).map((item) => 
                      (
                        <option value={item.id}>{item.case_name}</option>
                      )
                    )}    
                </select>
                </div>
                <div className="col-3 filter">
                  <button className="btn btn-md button sub-button" id="update-case" onClick={loadCaseBtnClick}>提交</button> 
                  {loading && <CircularProgress />}                 
                </div>

            </div>
            {caseResponse != null && (
                <div>
                    <div className="col-md-12 row" id="current-data">
                        <div className="col-md-1">日期:</div>
                        <div className="col-md-2"><span id="current-date" className="current-data">{currentDate}</span></div>
                        <div className="col-md-1">訪員: </div>
                        <div className="col-md-3"><span id="current-telemarketer" className="current-data">{caseResponse !=  null ? caseResponse.telemarketer_name : ""}</span>
                        <span id="current-telemarketer-id" className="hidden">{currentTelemarketerId}</span></div> 
                        <div className="col-md-2">專案名稱: </div>
                        <div className="col-md-3"><span id="current-case" className="current-data">{caseResponse !=  null ? caseResponse.case_name : ""}</span>
                            <span id="current-case-id" className="hidden">{currentCaseId}</span></div>
                    </div> 
                    
                    <div id="report" className="col-md-12">
                        <div className="col-md-1"></div>
                        <div id="time" className="col-md-3 report-block" >
                            <div className="row">
                                <div className="col-md-6">今日外撥時數:</div>
                                <div className="col-md-6">
                                    <input id="working-hours" className="form-control" type="text" disabled={disabled} value={workingHours} onChange={e => setWorkingHours(e.target.value)} onBlur={e => workingHoursChange(e.target.value)}/>
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">今日會議時數:</div>
                                <div className="col-md-6">
                                    <input id="meeting-hours" className="form-control" type="text" disabled={disabled} value={meetingHours} onChange={e => setMeetingHours(e.target.value)} onBlur={e => meetingHoursChange(e.target.value)}/>
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 goal-label">今日線上工時:</div>
                                <div className="col-md-3 goal-label"><span id="calling-hours">{caseResponse !=  null ? caseResponse.calling_hours : ""}</span></div>
                                <div className="col-md-3 goal-value" id="calling-time-ratio">{caseResponse !=  null ? caseResponse.calling_time_ratio : ""}</div>
                            </div>
                        </div>

                        <div id="goal" className="col-md-3 report-block">
                            <div className="row report-row">
                                <div className="col-md-6 goal-label">今日目標:</div>
                                <div className="col-md-4 goal-value" id="daily-goal">{caseResponse !=  null ? caseResponse.daily_goal : ""}</div>
                            </div>
                            <div className="row report-row">
                                <div className="col-md-6 goal-label">今日完成數:</div>
                                <div className="col-md-4 goal-label" id="success-count">{caseResponse !=  null ? caseResponse.successes_count : ""}</div>
                            </div>
                            <div className="row report-row">
                                <div className="col-md-6 goal-label">今日目標(%):</div>
                                <div className="col-md-4 goal-value" id="daily-goal-percent">{caseResponse !=  null ? 
                                  (caseResponse.daily_goal_percent != null ? 
                                      (caseResponse.daily_goal_percent + "%"): "0%"
                                  ) : ""
                                }</div>
                            </div>
                        </div>
                        
                        <div id="stats" className="col-md-3 report-block">
                            <div className="row report-row">
                                <div className="col-md-6">今日通數:</div>
                                <div className="col-md-3" id="calls-count">{caseResponse !=  null ? caseResponse.calls_count : ""}</div>
                            </div>
                            <div className="row report-row">
                                    <div className="col-md-6">平均通話秒數:</div>
                                    <div className="col-md-3" id="avg-call-time">{caseResponse !=  null ? caseResponse.avg_call_time : ""}</div>
                            </div>
                            <div className="row report-row">
                                <div className="col-md-6">300秒通數:</div>
                                <div className="col-md-3" id="long-calls-count">{caseResponse !=  null ? caseResponse.long_calls_count : ""}</div>
                            </div>                    
                            <div className="row report-row">
                                <div className="col-md-6">決策者通數:</div>
                                <div className="col-md-3" id="find-dm-count">{caseResponse !=  null ? caseResponse.find_dm_count : ""}</div>
                                <div className="col-md-3" id="find-dm-ratio">{caseResponse !=  null ? caseResponse.find_dm_ratio : ""}</div>
                            </div>
                            <div className="row report-row">
                                <div className="col-md-6">決策者平均通話秒數:</div>
                                <div className="col-md-3" id="find-dm-avg-call-time">{caseResponse !=  null ? caseResponse.find_dm_avg_call_time : ""}</div>
                            </div>
                            <div className="row report-row">
                                <div className="col-md-6">窗口拒絕計數:</div>
                                <div className="col-md-3" id="contact-reject-count">{caseResponse !=  null ? caseResponse.contact_reject_count : ""}</div>
                                <div className="col-md-3" id="contact-reject-ratio">{caseResponse !=  null ? caseResponse.contact_reject_ratio : ""}</div>
                            </div>
                        </div>

                        <div className="col-md-12" id="refresh">
                            <button className="btn btn-md button" id="refresh-btn" type="button" onClick={updateCaseBtnClick}>更新</button>
                        </div>              
                        <div className="col-md-12" id="tm-combine">
                            {loadingTmCombine && <CircularProgress />}
                            {tmCombineResponse != null && (
                                <table className='table table-hover table-sm'>
                                    <thead>
                                        <tr>
                                            {tmCombineResponse.headers.map((header) => (
                                                    <th scope='row'>{header}</th>       
                                                )
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tmCombineResponse.rows.map((row) => (
                                                <tr>
                                                    {row.map((value) => (
                                                            <td>{value}</td>
                                                        )
                                                    )}
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    </>
  );
};

export default TMDashboard;
